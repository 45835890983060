<template>
  <div>
    <div class="main-content-wrap d-flex flex-column">
      <div id="top">
        <Header title="Accounts" sub-title="Introducer"/>
        <Introduction />
        <b-container id="start" class="main-content questionnaire">
          <b-row>
            <b-col class="fieldset-wrapper" lg="7">
              <b-row>
                <b-col>
                  <SignUpForm/>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>
<script>

import SignUpForm from "./SignUpForm";
import Header from "../../../components/common/questionnaires/questionnaire/Header";
import Introduction from "../../../components/common/questionnaires/questionnaire/Introduction";

export default {
  name: 'SignUp',
  components: {Introduction, Header, SignUpForm},
  mounted () {
    this.$store.commit('removeTokens');


  }
}
</script>
