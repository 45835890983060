<template>
  <div class="fieldset">
    <template v-if="!show.login">
      <h3 class="fieldset-heading">Create Introducer Account</h3>
      <p>Register for account access to Introducer tools.</p>
    </template>

    <template v-else>
      <h3 class="fieldset-heading">Account Created</h3>
      <p>{{ show.loginMessage }}</p>
    </template>

    <template v-if="show.form">

      <QuestionBase
          :errors="[...errors.name]"
          :question="question.name"
          :valid="!!form.name"
          class="pb-0">
        <InputStandardBasic v-model="form.name"
                            :args="inputArgs.name"
                            :errors="errors.name"/>
      </QuestionBase>


      <QuestionBase
          :errors="[...errors.email]"
          :question="question.email"
          :valid="!!form.email"
          class="pb-0">
        <InputStandardBasic v-model="form.email"
                            :args="inputArgs.email"
                            :errors="errors.email"/>
      </QuestionBase>

      <QuestionBase
          :errors="[...errors.password]"
          :question="question.password"
          :valid="!!form.password && (form.password === form.password_repeat)"
          class="pb-0">
        <InputStandardBasic v-model="form.password"
                            :args="inputArgs.password"
                            :errors="[ ...errors.password]"/>
      </QuestionBase>

      <QuestionBase
          :errors="[...errors.password_repeat]"
          :question="question.password_repeat"
          :valid="!!form.password_repeat && (form.password === form.password_repeat)"
          class="pb-0">
        <InputStandardBasic v-model="form.password_repeat"
                            :args="inputArgs.password_repeat"
                            :errors="errors.password_repeat"/>
      </QuestionBase>


      <div class="button-nav">

        <button class="btn btn-forwards"
                @click="create"
        >Create
        </button>
      </div>

    </template>

    <template v-else-if="show.login">

      <div class="button-nav">
        <button class="btn btn-forwards"
                @click="login"
        >Login
        </button>
      </div>

    </template>

  </div>

</template>

<script>
import {http} from "@/services";
import {toast} from '@/mixins/toast'

import InputStandardBasic from "@/components/common/other/inputs/InputStandardBasic";
import QuestionBase from "@/components/common/questionnaires/question/QuestionBase";

export default {
  name: 'SignUpForm',
  components: {QuestionBase, InputStandardBasic},
  mixins: [toast],
  mounted() {
    this.setAccessCode()
  },
  data() {
    return {
      show: {
        form: true,
        login: false,
        loginMessage: null,
        security: false
      },
      form: {
        email: null,
        password: null,
        password_repeat: null,
        introducer_access_code: null
      },
      question: {
        email: {
          title: 'What is your email address?',
          subTitle: 'Your Email Address will be used as your username, please double check the email address is correct before confirming.',
          tip: null,
        },
        password: {
          title: 'Create a password:',
          subTitle: null,
          tip: null,
        },
        password_repeat: {
          title: null,
          subTitle: null,
          tip: null,
        },
        name: {
          title: 'What is your name?',
          subTitle: null,
          tip: null,
        }
      },
      inputArgs: {
        email: {
          placeholder: 'e.g. myemail@me.com',
          inputClass: 'field-75',
          label: null
        },
        password: {
          placeholder: null,
          inputClass: 'field-50',
          label: 'Password',
          type: 'password'
        },
        password_repeat: {
          placeholder: null,
          inputClass: 'field-50',
          label: 'Repeat Password',
          type: 'password'
        },
        name: {
          placeholder: 'e.g. Jane Dowsett',
          inputClass: 'field-50',
          label: null,
          type: 'text'
        }
      },
      errors: {
        email: [],
        password: [],
        password_repeat: [],
        name: []
      }
    }
  },
  methods: {
    create() {
      this.clearErrors()
      http.post('introducer_sign_up', this.form).then(
          () => {
            this.show.form = false
            this.show.login = true
            this.show.loginMessage = 'Success, your account is now created, you can now proceed to login.'
          }
      ).catch(
          error => {
            if ('detail' in error.response.data) {
                this.toast(error.response.data['detail'], 'Error', 'danger')
            } else {
              this.handleErrors(error.response.data)
            }
          }
      )
    },
    clearErrors() {
      let keys = Object.keys(this.errors)
      for (let i in keys) {
        this.errors[keys[i]] = []
      }
    },
    handleErrors(errors) {
      let keys = Object.keys(errors)
      for (let i in keys) {
        this.errors[keys[i]] = errors[keys[i]]
      }
    },
    setAccessCode() {
      if (this.$route.params.code) this.form.introducer_access_code = this.$route.params.code
    },
    login() {
      this.$store.dispatch('logoutIntroducer')
    }
  }
}
</script>
